import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./gallery.css"

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query CloudinaryImage {
      allCloudinaryMedia(filter: { secure_url: { regex: "^/gallery/" } }) {
        edges {
          node {
            secure_url
          }
        }
      }
    }
  `)
  const clImages = data.allCloudinaryMedia.edges
  return (
    <section className="section-gallery-content">
      {clImages.map((image, index) => (
        <div className="img-wrapper" key={`${index}-cl`}>
          <img
            src={image.node.secure_url}
            alt={`number ${index + 1}`}
            className="img"
          />
        </div>
      ))}
    </section>
  )
}

export default Gallery
