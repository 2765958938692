import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle/page-title"
import Gallery from "../components/Gallery/gallery"
import { useStateValue } from "../contexts/state-context-provider"

const GalleryPage = () => {
  const { language } = useStateValue()

  const hTitle = {
    en: "Photo Gallery",
    rs: "Galerija",
  }

  return (
    <Layout>
      <SEO title="gallery" />
      <PageTitle hTitle={hTitle[language]} />
      <Gallery />
    </Layout>
  )
}

export default GalleryPage
